<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="12"
    fill="none"
    viewBox="0 0 13 12"
  >
    <path
      fill="#fff"
      fill-rule="evenodd"
      d="M10.625 1.042c.46 0 .833.373.833.833v7.071a.833.833 0 1 1-1.666 0V3.887l-6.827 6.827a.833.833 0 0 1-1.179-1.178l6.827-6.827h-5.06a.833.833 0 1 1 0-1.667h7.072Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
